@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

.amplify-button {
	color: var(--amplify-components-text-color);
    border-color: var(--amplify-components-button-hover-border-color);
    color: var(--amplify-components-button-hover-color);
}
/* [data-amplify-router],
.federated-sign-in-button {
	border-radius: 30px !important;
}
.amplify-tabs button:first-child,
.amplify-tabs.amplify-flex {
	border-top-left-radius: 30px;
}
.amplify-tabs button:last-child,
.amplify-tabs.amplify-flex {
	border-top-right-radius: 30px;
}
.amplify-field-group__field-wrapper input {
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
}
.amplify-field-group__field-wrapper input[type="email"],
.amplify-button--primary {
	border-radius: 30px !important;
}
.amplify-field-group__outer-end button {
	border-top-right-radius: 30px !important;
	border-bottom-right-radius: 30px !important;
} */

.blank-convo-history {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.blank-convo-history h2 {
	font-size: 2rem;
}

#convo-history {
	max-height: 100%;
	height: calc(98vh - 100px);
	overflow-y: auto;
	position: relative;
	max-width: 90%;
	margin-right: auto;
	margin-left: auto;
}
.bottom-section {
	max-width: 90%;

}
@media only screen and (max-width: 767px) {
	#convo-history {
		width: 98%;
		height: calc(95vh - 100px);
	}
}
#convo-history ::-webkit-scrollbar {
	width: 6px;
}
#convo-history ::-webkit-scrollbar-thumb {
	background-color: #ccc;
	border-radius: 4px;
}

@keyframes typingEffect {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.animatedChar {
	opacity: 0;
	animation: typingEffect 0.3s forwards;
	display: inline-block;
}

.animatedCharImg {
	opacity: 0;
	animation: typingEffect 0.3s forwards;
	display: inline-block;
	height: 0.75em; /* Match the size of the text */
	margin-left: 5px;
	margin-top: -20px;
	width: auto; /* Keep the aspect ratio */
	transform: translateY(-10px);
}

  textarea {
	font-size: 16px;
  }
