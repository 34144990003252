.landing {
	text-align: center;
	padding: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
}

.landing h1 {
	font-size: 2rem;
	margin-bottom: 10px;
}

.landing p {
	font-size: 1.2rem;
	margin-bottom: 20px;
}

/* #signup {
	font-size: 1.1rem;
	color: white;
	background-color: var(--bg-color-dark);
	padding: 10px 20px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
	transition: background-color 0.3s;
	max-width: 300px;
	margin-right: auto;
	margin-left: auto;
} */

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}

#signup {
	font-size: 1.1rem;
	padding: 10px 20px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
	transition: background-color 0.3s;
	max-width: 300px;
	margin-right: auto;
	margin-left: auto;
	animation: pulse 2s infinite;
}
